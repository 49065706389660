<template>
    <div>
  
  
  <!-- Modal -->
  <div class="modal  fade"  id="AddScriptModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="AddScriptModal">
    <div class="modal-dialog modal-xl ">
      <div class="modal-content " >
        <div class="container p-2 pb-4" >
            <div class="row ">
            <div class="d-flex col-12 justify-content-end">
                 <button
                     @click="closeModal"
            class="btn"
            style=""><i class="fa fa-times" aria-hidden="true"></i></button>
            </div>
            </div>
          <div class="row">
              
              <div class="col-12 col-lg-7"><CodeEditor :line-nums="true" height="75vh" max-width="600px" spellcheck="false" id="jsCodeText" v-model.trim="jsCode" theme="light" width="100%"></CodeEditor>
                  <button @click="addScript()"  id="saveDataButton" class="outline-none float-right mt-3 mr-3 SaveScriptBtn btn outline-none border-0 px-3 py-1"><span data-v-4d28f6ca="">Add</span></button>
              </div>
              <div class="col-12 col-lg-5" style="max-height: 75vh; overflow: auto;">
                   <p class="w-100" style="color: rgb(41, 41, 41); font-weight: 600; font-size: 24px;"> How it works</p>
                   <div class="m-0 ">
                       <p class="m-0 font-weight-bold">Use JS code without script tag </p>
                       <div class="hljs rounded">
                           <div class="d-flex justify-content-between pt-2 px-2">
                               <code>JS</code>
                              </div>
                              <pre  v-highlight><code  class="language-js pt-0" v-html="jsCodeText"></code></pre>
                          </div>
                   </div>
                     <div class="">
                       <p class="m-0 font-weight-bold">Quizell Variables </p>
                       <span style="color:#a0a1a7;font-size: 14px">Use variables to retrieve data corresponding to their values.</span>
                       <div class="hljs rounded">
                           <div class="d-flex justify-content-between pt-2 px-2 ">
                               <code>JS</code>
                              </div>
                              <pre  v-highlight><code  class="language-js pt-0" v-html="jsVariableText"></code></pre>
                          </div>
                   </div>
                   <div style="color:#a0a1a7;font-size: 14px">Use semi-colon operator at the end of line;</div>
                   <div style="color:#a0a1a7;font-size: 14px">For more details follow this <a href="https://support.quizell.com/blogs/create-a-quiz/how-to-use-script" target="_blank">article</a> </div>
              </div>
              <!-- <div class="col-12" v-else>
                  <WebUserPlans/>
              </div> -->
              
          </div>
         </div>
  
      </div>
    </div>
  </div>
    </div>
  </template>
  
  <script>
  import hljs from 'highlight.js';
  import Vue from 'vue';
  import {mapGetters} from "vuex";
  import CodeEditor from 'simple-code-editor';
  // import WebUserPlans from "./WebUserPlans.vue"
  
  import $ from 'jquery'
  Vue.directive('highlight', {
    deep: true,
    bind(el ) {
      // Check if the element contains code blocks
      const blocks = el.querySelectorAll('code');
      blocks.forEach((block) => {
        // Apply highlighting
        hljs.highlightBlock(block);
      });
    },
    componentUpdated(el ) {
      // Re-highlight when component is updated
      const blocks = el.querySelectorAll('code');
      blocks.forEach((block) => {
        hljs.highlightBlock(block);
      });
    },
  });
  export default {
      components:{
          CodeEditor
      },
      props:{
          scriptCode:String
      },
  data(){
      return{
          
           jsCodeText: 
  `//Wrong ❌
  &#60;script&#62;
  console.log('Hello World');
  &#60;/script&#62;
  //Correct ✅
  console.log('Hello World');`,
  jsVariableText:
  
  `//Get lead information.
  window.quizell.leadData;
  
  //Get  list of all the questions that are being used in this quiz.
  window.quizell.allQuestions;
  
  //Get the list of options that are selected by the user.
  window.quizell.selectedQuestionOptions;
  
  //Get the list of products that on the basis of selected questions.
  window.quizell.products;
  `,
          jsCode:this.scriptCode?this.scriptCode:'//Add script without script tag\n//variables --> window.quizell.leadData, window.quizell.allQuestions, \n//window.quizell.selectedQuestionOptions , window.quizell.products.'
      }
  },
  mounted(){
         
  
      // this.OpenModal()
  },
  
  // nextButtonScript(){
  // // sciprt
  // },
  
      methods:{
          OpenModal(){
          
          document.querySelector('.code_area textarea').setAttribute("spellcheck", "false");
          //  document.querySelector('.code_area textarea').style.overflow='hidden';
          // document.querySelector('.code_area pre').classList.add('wrap-code')
              
          this.jsCode=this.scriptCode?this.scriptCode:'//your JS code here...'
          console.log('scriptCode');
          $('#AddScriptModal').modal('show')
      },
          closeModal(){
              $('#AddScriptModal').modal('hide')
          },
          addScript()
          {
              this.closeModal();
              this.$emit('saveScript',this.jsCode)
          }
      },
      computed:{
           ...mapGetters( ["getIsShopifyUser"]),
           IsShopifyUser(){
      return this.getIsShopifyUser
     }
      }
  }
  </script>
  <style>
  .wrap-code{
       max-width: 600px;
       width: 600px!important;
       text-wrap: wrap;
  }
  </style>
  <style scoped>
  .SaveScriptBtn{
      background: #4d1b7e;
      border: 1px solid #4d1b7e;
      color: #ffffff;
  }
  .AddScriptModalStyle{
      max-height:80vh;
      overflow: hidden scroll;
      scroll-behavior: smooth;
  }
  .modal-content
  {
      border-radius: 20px;
          overflow: hidden;
  }
  
  .modal-btn{
      background:#ffa201;
      color:#ffffff;
      border: none;
  }
  .modal-btn:hover{
      color:#ffffff;
  }
  
  .btn-profile-seeting
  {
      border: 1px solid #ffa201;
      color: #ffa201;
  }
  
  .btn-profile-seeting:hover
  {
      border: 1px solid #ffa201;
      color: #ffffff;
      background:#FFA201;
  }
  @import url('https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.3.1/styles/atom-one-dark.min.css');
  
  .TitleHead{
      font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  
  color: #18191C;
  }
  
  /* 
  .topSection{
      color:#ffffff;
       font-family: 'Poppins';
  }
  .topSection .title p{
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  }
  
   .priceSection .price{
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  }
   .priceSection .subText{
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  }
  
  .priceText span{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  }
  
  .buttonSection a{
      background:transparent;
      border: 1.02182px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 5.44969px 16.3491px -2.72485px rgba(141, 49, 192, 0.3);
  border-radius: 25px;
  font-size:14px;
  font-weight:500;
  color:#ffffff;
  }
  .buttonSection a:hover{
      background:#ffffff;
  color:#042542;
  }
  
  .midSection{
      font-family: 'Poppins';
      color: #535355;
  }
  .midTitle{
      font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  }
  
  .midFeatures p{
      font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  }
  .midFeatures p span{
  font-weight: 600;
  }
  .midCheckFeatures p{
      font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  color: #535355;
  }
  
  .endSection p{
      color: #535355;
      font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  } */
  </style>
  
